import axios from "axios"
const API_URL = process.env.REACT_APP_API_BASE_URL;


export const createLh = async (payload) => {
    try {
        let response = await axios.post(`${API_URL}/lh/create`, payload);
        return response.data;
        // console.log("response", response)
    } catch (error) {
        console.error("Error creating lh:", error);
        throw error;

    }
}


export const getAllLhs = async (name) => {
    try {
        const response = await axios.post(`${API_URL}/lh?name=${name}`);
        return response.data.data;
    } catch (error) {
        console.error("Error fetching lhs:", error);
        throw error;
    }
}


export const getAllUniqueValue = async (query) => {
    try {
        const response = await axios.post(`${API_URL}/lh/unique?query=${query}`);
        return response.data.data;

    } catch (error) {
        console.log(error);
        throw error;

    }
}


export const getSingleLR = async (id) => {
    try {
        const response = await axios.post(`${API_URL}/lh/get?id=${id}`);
        return response.data.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}


export const upddatLR = async (id, data) => {
    try {
        const response = await axios.post(`${API_URL}/lh/update?id=${id}`, data);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const makeAutoConsignmentNoteNo = async () => {
    try {
        const res = await axios.get(`${API_URL}/lh/consignment-note`);
        return res.data.data;
    } catch (error) {
        console.log(error);
    }
}