import React, { useState, useCallback, useRef } from "react";
import Webcam from "react-webcam";
import { Aperture, Camera, RotateCcw } from "lucide-react";

const Home = ({ onCapture }) => {
  const [capturedImage, setCapturedImage] = useState(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [facingMode, setFacingMode] = useState("environment");
  const webcamRef = useRef(null);

  // console.log("capturedImage",capturedImage)

  const videoConstraints = {
    width: 720,
    height: 480,
    facingMode: facingMode
  };

  const startCamera = () => {
    setIsCameraOpen(true);
  };

  const stopCamera = () => {
    setIsCameraOpen(false);
  };


  const captureImage = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    stopCamera();
    // Send the captured image to the parent component
    if (onCapture) {
      onCapture(imageSrc);  // Pass the captured image to the onCapture prop
    }
  }, [webcamRef, onCapture]);

  const switchCamera = () => {
    setFacingMode(prevMode =>
      prevMode === "user" ? "environment" : "user"
    );
  };

  return (
    // <div className="home-container">
    // <form className="form">
    <div className="mb-6">
      <div className="mt-1 flex flex-col items-center justify-center px-6 pt-5 pb-6 border-2 border-gray-600 border-dashed rounded-md">
        {!isCameraOpen && !capturedImage && (
          <button
            type="button"
            onClick={startCamera}
            className="flex flex-col items-center justify-center"
          >
            <Camera className="w-12 h-12 text-gray-400" />
            <span className="mt-2 text-sm text-gray-500">Open Camera</span>
          </button>
        )}
        {isCameraOpen && (
          <div className="relative">
            <Webcam
              audio={false}
              height={480}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={720}
              videoConstraints={videoConstraints}
              // forceScreenshotSourceSize={true} screenshotQuality={1}
              className="rounded-lg shadow-lg"
            />
            <button
              type="button"
              onClick={captureImage}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded absolute bottom-4 left-1/2 transform -translate-x-1/2"
            >
              {/* Capture Image */}
              <Aperture />
            </button>
            <button
              type="button"
              onClick={switchCamera}
              className="mt-4 p-2 bg-gray-200 rounded-full absolute top-4 right-4"
            >
              <RotateCcw className="w-6 h-6 text-gray-600" />
            </button>
          </div>
        )}
        {capturedImage && (
          <div className="mt-4">
            <img src={capturedImage} alt="Captured" className="max-w-full h-auto rounded-lg shadow-lg" />
            <button
              type="button"
              onClick={() => { setCapturedImage(null); startCamera(); }}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
            >
              Retake Photo
            </button>
          </div>
        )}
      </div>
    </div>
    // </form>
    // </div>
  );
};

export default Home;