import axios from "axios"
const API_URL = process.env.REACT_APP_API_BASE_URL;
export const createNewIssue = async (payload) => {
    try {
        let response = await axios.post(`${API_URL}/issue`, payload);
        return response.data;
        // console.log("response", response)
    } catch (error) {
        console.error("Error creating issue:", error);
        throw error;

    }
}


export const searchEmployeesService = async (name) => {
    try {
        const response = await axios.get(`${API_URL}/employee/public?name=${name}`);
        return response.data.data;
    } catch (error) {
        console.error("Error searching employees:", error);
        throw error;
    }
};

export const searchHubService = async (payload) => {
    try {
        const response = await axios.get(`${API_URL}/hubs/all?name=${payload.name}`);
        return response.data.data;

    } catch (error) {
        console.error("Error searching hub:", error);
        throw error;
    }
}