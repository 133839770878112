import React, { useEffect, useState } from 'react';
import { CircleX } from 'lucide-react';
import Home from './Home';
import { getBase64FromDataURL } from "../Cod/helper";

const initialFormData = {
  vehicle_image: "",
  rc_image: "",
};

const CustomSelectInput = ({ handleRightClick, isOpen, close }) => {
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [imageData, setImageData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const formattedValue = e.target.value.replace(/\s+/g, '').toUpperCase(); // Remove all spaces
    setVehicleNumber(formattedValue);
  };

  // Construct the payload as expected for the PUT request
  const payload = {
    vehicle_number: vehicleNumber,  // Flattening the array of vehicle numbers
    vehicle_image: getBase64FromDataURL(imageData.vehicle_image),
    rc_image: getBase64FromDataURL(imageData.rc_image)
  };
  // console.log("payload", payload);

  useEffect(() => {
    if (isOpen) {
      // Optional: Reset the vehicle number when the modal opens
      setVehicleNumber('');
    }
  }, [isOpen]);


  const handleCapturedImage = (image) => {
    console.log("Image received from Home:", image);
    setImageData((prevFormData) => ({
      ...prevFormData,
      vehicle_image: image, // Store the image as data URL
    }));

    console.log("Image captured:", image);
  };

  const handleCapturedOdometerImage = (rc_image) => {
    console.log("Image received from Home:", rc_image);
    setImageData((prevFormData) => ({
      ...prevFormData,
      rc_image: rc_image, // Store the image as data URL
    }));

    console.log("Image odometer captured:", rc_image);
  };

  return (
    <div>
      {/* Main modal */}
      {isOpen && (
        <div
          className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-gray-900 bg-opacity-50 overflow-x-hidden overflow-y-auto"
          aria-hidden="true"
        >
          <div className="relative p-4 w-full max-w-sm max-h-screen overflow-y-auto"> {/* Further reduced width */}
            {/* Modal content */}
            <div className="relative bg-black rounded-lg shadow-lg p-6">
              <div className='absolute top-3 right-3 cursor-pointer' onClick={close}>
                <CircleX size={15} />
              </div>
              <h2 className="text-lg font-semibold mb-4 text-white text-left">
                Add Vehicle Details
              </h2>
              <div>
                <label
                  htmlFor="vehicle_image"
                  className="block text-sm font-medium text-gray-300 mb-2"
                >
                  Upload Vehicle Image
                </label>
                <Home
                  onCapture={handleCapturedImage}
                />
              </div>
              <div>
                <label
                  htmlFor="rc_image"
                  className="block text-sm font-medium text-gray-300 mb-2"
                >
                  Upload Vehicle RC Image
                </label>
                <Home
                  onCapture={handleCapturedOdometerImage}
                />
              </div>
              <div>
                <label
                  htmlFor="vehicle_input"
                  className="block text-sm font-medium text-gray-300 mb-2"
                >
                  Add vehicle Number
                </label>
                <input
                  type="text"
                  id="vehicle_input"
                  name="vehicle_input"
                  placeholder="Enter Vehicle Number"
                  value={vehicleNumber} // Bind input value to state
                  onChange={handleInputChange} // Call function on change
                  className="block w-full bg-[#1F1F1F] border border-gray-600 rounded-md shadow-sm p-2 text-white"
                />
              </div>

              <button
                onClick={() => handleRightClick(payload)} // Pass vehicleNumber as a parameter
                className="w-full px-4 py-2 bg-white text-black hover:bg-blue-700 rounded-md transition-colors mt-4"
              >
                ADD
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelectInput;