import React, { useState } from 'react';
import { createNewIssue } from './service/lossesRecoverService';
import { CircleCheckBig } from 'lucide-react';
import SearchEmployee from './components/SearchEmployee';
import HubSearch from './components/HubSearch';

const LossesAndRecoverForm = () => {
    const [selectedTab, setSelectedTab] = useState('Shipment');
    const tabOptions = ['Shipment', 'Cash'];

    return (
        <div className="max-w-md mx-auto p-6 bg-[#191919] rounded-lg shadow-xl text-gray-100">
            <h2 className="text-[16px] font-medium mb-6 text-center text-white-400">
                DeliveryPlus - Losses & Recovery
            </h2>
            <div className="flex flex-col items-center">
                <div className="flex space-x-4">
                    {tabOptions.map((option) => (
                        <button
                            key={option}
                            onClick={() => setSelectedTab(option)}
                            className={`px-4 py-2 rounded-md transform transition-all duration-300 ${selectedTab === option
                                ? 'bg-gradient-to-r from-blue-500 to-blue-700 text-white scale-105 shadow-lg'
                                : 'bg-gradient-to-r from-gray-100 to-gray-300 text-gray-700 hover:scale-105 hover:shadow-md'
                                }`}
                        >
                            {option}
                        </button>
                    ))}
                </div>
                <div className="mt-6 w-full">
                    {selectedTab === 'Shipment' && <ShipmentTab />}
                    {selectedTab === 'Cash' && <CashTab />}
                </div>
            </div>
        </div>
    );
};

const ShipmentTab = () => {
    const [shipmentData, setShipmentData] = useState({});
    const [errors, setErrors] = useState({});
    const [currentView, setCurrentView] = useState({ form: true, sucess: false });
    const [selectedEmployee, setSelectedEmployee] = useState([]);

    const ShipmentForm = [
        // { label: "Issue ID", name: "issue_id", type: "text", inputFormat: "input", required: true },
        // { label: 'Issue Type', name: 'issue_type', type: 'text', inputFormat: 'select', required: true, options: [{ value: 'shipment_loss', label: 'Shipment Loss' }, { value: 'cash_loss', label: 'Cash Loss' }] },
        { label: 'Shipment ID', name: 'shipment_id', type: 'text', inputFormat: 'input', required: true },
        {
            label: "Shipment Loss Type", name: "shipment_type", type: "text", inputFormat: "select", required: true,
            options:
                [
                    { label: "Shipment Damage", value: "shipment_damage" },
                    { label: "Shipment Stolen", value: "shipment_stolen" },
                    { label: "Shipment Lost", value: "shipment_lost" }
                ]
        },
        { label: 'Hub ID', name: 'hub_id', type: 'text', inputFormat: 'input', required: true },
        { label: 'Amount', name: 'amount', type: 'number', inputFormat: 'input', required: true },
        { label: 'Incident Date', name: 'incident_date', type: 'date', inputFormat: 'input', required: true },
        { label: 'Remarks', name: 'remarks', type: 'text', inputFormat: 'textarea', required: false },
        { label: 'Loss Reason', name: 'loss_reason', type: 'text', inputFormat: 'textarea', required: false },
        { label: 'Individuals Involved', name: 'associated_involved', type: 'text', inputFormat: 'input', required: false },

    ];


    // Validate individual fields
    const validateField = (name, value) => {
        let error = '';
        if (!value && ShipmentForm.find(field => field.name === name)?.required) {

            error = 'This field is required';

        } else if (name === 'amount' && isNaN(value)) {
            error = 'Amount must be a number';
        }
        return error;
    };

    // Validate the entire form
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};
        setShipmentData(prevData => ({
            ...prevData,
            associated_involved: selectedEmployee.map(item => item.id),
        }));
        ShipmentForm.forEach(({ name }) => {
            const error = validateField(name, shipmentData[name]);
            if (error) {
                formIsValid = false;
                newErrors[name] = error;
            }
        });

        setErrors(newErrors);
        return formIsValid;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log('Form submitted:', shipmentData);
            // Proceed with form submission logic here
            let res = await createNewIssue({ ...shipmentData, issue_type: "shipment_loss", associated_involved: selectedEmployee?.map(item => item.id) });
            if (res) {
                setShipmentData({});
                setCurrentView({ ...currentView, form: false, success: true });
            }
        }
    };

    // Handle field changes
    const handleChange = (e) => {
        let { name, value } = e.target;
        if (name === "amount") {
            value = Number(value);
            value = +value;
        }
        setShipmentData(prevData => ({
            ...prevData,
            [name]: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: validateField(name, value)
        }));

    };

    const handleRemoveEmployee = (index) => {
        setSelectedEmployee(prevEmployees => prevEmployees.filter((_, i) => i !== index));
    }
    return (
        <div className="p-4 rounded-md">
            <h2 className="text-sm font-medium text-gray-500">Step {currentView.form ? 1 : 2} of 2</h2>
            <h3 className="text-lg font-medium text-white-500">Loss Reporting</h3>
            {currentView.form &&
                <form onSubmit={handleSubmit}>
                    {ShipmentForm.map((field, index) => (
                        <div key={index} className="my-4">
                            <label htmlFor={field.name} className="block text-sm font-medium text-gray-300 mb-1">
                                {field.label}
                            </label>
                            {field.inputFormat === "input" && field.name === "associated_involved" ? (<div>
                                <div className="flex flex-wrap gap-2 mb-2">
                                    {selectedEmployee?.map((emp, index) => (
                                        <div className='flex flex-row gap-2 bg-[#1F1F1F] p-1 rounded-md'>
                                            <p>{emp?.name}</p> <p onClick={() => handleRemoveEmployee(index)} className='text-red-500 hover:cursor-pointer'>X</p>
                                        </div>
                                    ))}
                                </div>
                                <SearchEmployee setEmployess={setSelectedEmployee} />
                            </div>) :
                                field.inputFormat === "input" && field.name === "hub_id" ? (
                                    <div>
                                        <div>
                                            {shipmentData?.hub_id && <p className='flex flex-row gap-2 bg-[#1F1F1F] p-1 rounded-md mb-2 w-[130px]'>{shipmentData?.hub_id} <span onClick={() => setShipmentData({ ...shipmentData, hub_id: "" })} className='text-red-500 hover:cursor-pointer'>X</span></p>}
                                        </div>
                                        <div>
                                            <HubSearch setEmployess={(vlaue) => setShipmentData({ ...shipmentData, hub_id: vlaue })} />
                                        </div>
                                    </div>
                                ) :

                                    field.inputFormat === 'input' ? (

                                        <input
                                            type={field.type}
                                            name={field.name}
                                            id={field.name}
                                            value={shipmentData[field.name] || ''}
                                            onChange={handleChange}
                                            placeholder={`Enter ${field.label}`}
                                            className={`block w-full px-3 py-2 mb-1 bg-[#1F1F1F] text-base text-white transition duration-150 ease-in-out border ${errors[field.name] ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                                        />
                                    ) :

                                        field.inputFormat === 'textarea' ? (
                                            <textarea
                                                name={field.name}
                                                id={field.name}
                                                value={shipmentData[field.name] || ''}
                                                onChange={handleChange}
                                                rows="3"
                                                placeholder={`Enter ${field.label}`}
                                                className={`block w-full px-3 py-2 mb-1 bg-[#1F1F1F] text-base text-white transition duration-150 ease-in-out border ${errors[field.name] ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                                            />
                                        ) :
                                            field.inputFormat === 'select' ? (
                                                <select
                                                    name={field.name}
                                                    id={field.name}
                                                    value={shipmentData[field.name] || ''}
                                                    onChange={handleChange}
                                                    className={`block w-full px-3 py-2 mb-1 bg-[#1F1F1F] text-base text-white transition duration-150 ease-in-out border ${errors[field.name]
                                                        ? 'border-red-500'
                                                        : 'border-gray-300'} rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                                                >
                                                    <option value="">Select {field.label}</option>
                                                    {field.options.map(option => (
                                                        <option key={option.value} value={option.value}>{option.label}</option>
                                                    ))}
                                                </select>
                                            ) : null}

                            {errors[field.name] && (
                                <p className="mt-1 text-sm text-red-500">{errors[field.name]}</p>
                            )}
                        </div>
                    ))}

                    <button
                        type="submit"
                        className="w-full px-4 py-2 text-base font-semibold text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Submit
                    </button>
                </form>
            }

            {currentView.success && (
                <div className="w-full h-screen flex flex-col gap-28 pt-10 animate-slide-in-right">
                    <div className="flex flex-col items-center gap-6">
                        <CircleCheckBig className="w-16 h-16 text-white animate-bounce" />
                        <p className="text-2xl ">Completed</p>
                    </div>
                    <p className="text-green.500 text-center">
                        Your Issue Is Added successful. Our team will take action shortly.
                        <br />
                        Thank you for your patience and cooperation.
                    </p>
                </div>
            )}
        </div>
    );
};




const CashTab = () => {
    const [cashData, setCashData] = useState({});
    const [errors, setErrors] = useState({});
    const [currentView, setCurrentView] = useState({ form: true, success: false });
    const [selectedEmployee, setSelectedEmployee] = useState([]);

    const cashForm = [
        // {
        //     label: "Cash Loss Type",
        //     name: "cash_loss_type",
        //     type: "text",
        //     inputFormat: "select",
        //     required: true,
        //     options: [
        //         { label: "Cash Shortage", value: "cash_shortage" },
        //         { label: "Cash Theft", value: "cash_theft" },
        //         { label: "Accounting Error", value: "accounting_error" }
        //     ]
        // },
        {
            label: 'Hub ID',
            name: 'hub_id',
            type: 'text',
            inputFormat: 'input',
            required: true
        },
        {
            label: 'Amount',
            name: 'amount',
            type: 'number',
            inputFormat: 'input',
            required: true
        },
        {
            label: 'Incident Date',
            name: 'incident_date',
            type: 'date',
            inputFormat: 'input',
            required: true
        },
        {
            label: 'Individuals Involved',
            name: 'associated_involved',
            type: 'text',
            inputFormat: 'input',
            required: false
        },
        {
            label: 'Loss Reason',
            name: 'loss_reason',
            type: 'text',
            inputFormat: 'textarea',
            required: true
        },
        {
            label: 'Additional Remarks',
            name: 'remarks',
            type: 'text',
            inputFormat: 'textarea',
            required: false
        }
    ];

    const validateField = (name, value) => {
        let error = '';
        if (!value && cashForm.find(field => field.name === name)?.required) {
            error = 'This field is required';
        } else if (name === 'amount') {
            if (isNaN(value)) {
                error = 'Amount must be a number';
            } else if (value <= 0) {
                error = 'Amount must be greater than 0';
            }
        }
        return error;
    };

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};
        setCashData(prevData => ({
            ...prevData,
            associated_involved: selectedEmployee.map(item => item.id),
        }));

        cashForm.forEach(({ name }) => {
            const error = validateField(name, cashData[name]);
            if (error) {
                formIsValid = false;
                newErrors[name] = error;
            }
        });

        setErrors(newErrors);
        return formIsValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                let res = await createNewIssue({
                    ...cashData,
                    issue_type: "cash_loss",
                    associated_involved: selectedEmployee?.map(item => item.id)
                });
                if (res) {
                    setCashData({});
                    setCurrentView({ form: false, success: true });
                }
            } catch (error) {
                console.error('Error submitting cash loss:', error);
                // Handle error appropriately
            }
        }
    };

    const handleChange = (e) => {
        let { name, value } = e.target;
        if (name === "amount") {
            value = Number(value);
        }
        setCashData(prevData => ({
            ...prevData,
            [name]: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: validateField(name, value)
        }));
    };

    const handleRemoveEmployee = (index) => {
        setSelectedEmployee(prevEmployees => prevEmployees.filter((_, i) => i !== index));
    };

    return (
        <div className="p-4 rounded-md">
            <h2 className="text-sm font-medium text-gray-500">Step {currentView.form ? '1' : '2'} of 2</h2>
            <h3 className="text-lg font-medium text-white-500 mb-4">Cash Loss Reporting</h3>

            {currentView.form && (
                <form onSubmit={handleSubmit}>
                    {cashForm.map((field, index) => (
                        <div key={index} className="my-4">
                            <label htmlFor={field.name} className="block text-sm font-medium text-gray-300 mb-1">
                                {field.label}
                            </label>
                            {field.inputFormat === "input" && field.name === "associated_involved" ? (
                                <div>
                                    <div className="flex flex-wrap gap-2 mb-2">
                                        {selectedEmployee?.map((emp, index) => (
                                            <div key={emp.id} className="flex flex-row gap-2 bg-[#1F1F1F] p-1 rounded-md">
                                                <p>{emp?.name}</p>
                                                <p onClick={() => handleRemoveEmployee(index)} className="text-red-500 hover:cursor-pointer">X</p>
                                            </div>
                                        ))}
                                    </div>
                                    <SearchEmployee setEmployess={setSelectedEmployee} />
                                </div>
                            ) : field.inputFormat === "input" && field.name === "hub_id" ? (
                                <div>
                                    <div>
                                        {cashData?.hub_id && (
                                            <p className="flex flex-row gap-2 bg-[#1F1F1F] p-1 rounded-md mb-2 w-[130px]">
                                                {cashData?.hub_id}
                                                <span onClick={() => setCashData({ ...cashData, hub_id: "" })} className="text-red-500 hover:cursor-pointer">X</span>
                                            </p>
                                        )}
                                    </div>
                                    <HubSearch setEmployess={(value) => setCashData({ ...cashData, hub_id: value })} />
                                </div>
                            ) : field.inputFormat === 'input' ? (
                                <input
                                    type={field.type}
                                    name={field.name}
                                    id={field.name}
                                    value={cashData[field.name] || ''}
                                    onChange={handleChange}
                                    className={`block w-full px-3 py-2 mb-1 bg-[#1F1F1F] text-base text-white transition duration-150 ease-in-out border ${errors[field.name] ? 'border-red-500' : 'border-gray-300'
                                        } rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                                />
                            ) : field.inputFormat === 'textarea' ? (
                                <textarea
                                    name={field.name}
                                    id={field.name}
                                    value={cashData[field.name] || ''}
                                    onChange={handleChange}
                                    rows="3"
                                    className={`block w-full px-3 py-2 mb-1 bg-[#1F1F1F] text-base text-white transition duration-150 ease-in-out border ${errors[field.name] ? 'border-red-500' : 'border-gray-300'
                                        } rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                                />
                            ) : field.inputFormat === 'select' ? (
                                <select
                                    name={field.name}
                                    id={field.name}
                                    value={cashData[field.name] || ''}
                                    onChange={handleChange}
                                    className={`block w-full px-3 py-2 mb-1 bg-[#1F1F1F] text-base text-white transition duration-150 ease-in-out border ${errors[field.name] ? 'border-red-500' : 'border-gray-300'
                                        } rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                                >
                                    <option value="">Select {field.label}</option>
                                    {field.options.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            ) : null}

                            {errors[field.name] && (
                                <p className="mt-1 text-sm text-red-500">{errors[field.name]}</p>
                            )}
                        </div>
                    ))}

                    <button
                        type="submit"
                        className="w-full px-4 py-2 text-base font-semibold text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Submit
                    </button>
                </form>
            )}

            {currentView.success && (
                <div className="w-full h-screen flex flex-col gap-28 pt-10 animate-slide-in-right">
                    <div className="flex flex-col items-center gap-6">
                        <CircleCheckBig className="w-16 h-16 text-white animate-bounce" />
                        <p className="text-2xl">Completed</p>
                    </div>
                    <p className="text-green-500 text-center">
                        Your cash loss report has been submitted successfully. Our team will review and take action shortly.
                        <br />
                        Thank you for your patience and cooperation.
                    </p>
                </div>
            )}
        </div>
    );
};

export default LossesAndRecoverForm;


