import React, { useEffect, useState } from 'react';
import { searchEmployeesService } from '../service/lossesRecoverService';

const SearchEmployee = ({ setEmployess }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [result, setResult] = useState([]);
    const [showResult, setShowResult] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (searchTerm.trim()) {
                const res = await searchEmployeesService(searchTerm);
                setResult(res);
            } else {
                setResult([]);
            }
        };
        fetchData();
    }, [searchTerm]);
    const handleSelectionClick = (item) => {
        setEmployess(pre => {
            if (!pre.some(employee => employee.id === item.id)) {
                return [...pre, { id: item.id, name: item.name }];
            }
            return pre;
        });
        setSearchTerm('');
        setShowResult(false);
    }
    return (
        <div className="relative w-full max-w-md mx-auto">
            <input
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                type="text"
                onFocus={() => setShowResult(true)}
                onBlur={() => setTimeout(() => setShowResult(false), 150)} // Delay to allow click on results
                placeholder="Search employees..."
                className="w-full px-4 py-3  rounded-md bg-[#0000] border border-white-500 text-[#fff] focus:outline-none focus:ring-2 focus:ring-black-300"
            />

            {showResult && Array.isArray(result) && result.length > 0 && (
                <div
                    className="absolute top-full left-0 w-full max-h-30 overflow-y-auto mt-2 bg-white border border-black-500 rounded-md shadow-lg"
                    style={{ maxHeight: '90px' }}
                >
                    {result.map((item, index) => (
                        <div
                            key={index}
                            className="p-2 hover:bg-black-100 cursor-pointer"
                            onClick={() => handleSelectionClick(item)}
                        >
                            <p className="text-sm text-gray-700">{item.name}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchEmployee;
