import React, { useState, useEffect, useCallback } from 'react';
import { Clock, Printer, XIcon } from 'lucide-react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { toast } from 'sonner';
import { createLh, getAllUniqueValue, getSingleLR, makeAutoConsignmentNoteNo, upddatLR } from './lhServices';
import { useSearchParams } from 'react-router-dom';
import { validationForConsignmentNoteNo } from './utils/validationForConsignmentNoteNo';
import { debounce } from 'lodash';

const DeliveryServiceForm = () => {
    const initialFormData = {
        companyPAN: '', companyGST: '', companyAddress: '',
        consignmentNoteNo: '', consignmentNoteDate: '',
        origin: '', destination: '', vehicleNo: '',
        consignorAddress: '', consigneeAddress: '',
        invoiceDate: '', eWayBill: [''], goodsValue: [""],
        invoiceDetails: [""], packages: [''],
        commertialValues: [""],
        issuingOffice: '', packingMode: '',
        gstPayableBy: 'consignor',
        date: new Date().toISOString().split('T')[0],
        customFields: [],
        remarks: ''
    };
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const consignmentId = searchParams.get("id");
    // const [, setConsignmentNoteNo] = useState(null);


    const [formData, setFormData] = useState(initialFormData);
    const [searchingResultsConfig, setSearchingResultsConfig] = useState({
        searchByPan: false,
        searchByGST: false,
        issueOfficeOrAgent: false,
        companyAddress: false,
        consignorAddress: false,
        consigneeAddress: false,
        origin: false,
        vehicleNo: false,
        packingMode: false,
        consignmentNoteNo: false,
        destination: false,
        email: false

    });
    const [loadingAndErrorConfiguration, setLoadingAndErrorConfiguration] = useState({ isLoading: false, isError: false });
    const [uniqueValue, setUniqueValue] = useState([]);

    const handleInputChange = useCallback((field, value, index) => {
        setFormData(prev => {
            if (Array.isArray(prev[field]) && index !== undefined) {
                const newArray = [...prev[field]];
                newArray[index] = value;
                return { ...prev, [field]: newArray };
            } else {
                return { ...prev, [field]: value };
            }
        });
    }, []);

    useEffect(() => {
        if (!id) {
            (async () => {
                const consignmentNoteNo = await makeAutoConsignmentNoteNo();
                // setConsignmentNoteNo(consignmentNoteNo);
                setFormData(prev => ({ ...prev, consignmentNoteNo: consignmentNoteNo }));
            })()
        }
        else {
            setFormData(prev => ({ ...prev, consignmentNoteNo: id }))
        }
    }, [id])

    const populateData = useCallback((data) => {
        const {
            id,
            consignmentNoteNo,
            invoiceDate,
            eWayBill,
            goodsValue,
            commertialValues,
            invoiceDetails,
            packages,
            consignmentNoteDate,
            ...rest
        } = data;

        setFormData({
            ...rest,
            eWayBill: consignmentId ? eWayBill : [''],
            goodsValue: consignmentId ? goodsValue : [''],
            invoiceDetails: consignmentId ? invoiceDetails : [''],
            commertialValues: consignmentId ? commertialValues : [''],
            packages: consignmentId ? packages : [''],
            consignmentNoteDate
        });

        setSearchingResultsConfig({ searchByPan: false, searchByGST: false });
    }, [consignmentId]); // Add dependencies here

    useEffect(() => {
        if (id) {
            console.log(id);
            (async () => {
                try {

                    const res = await getSingleLR(id);
                    console.log(res);
                    if (res) {
                        populateData(res);
                    }
                } catch (error) {
                    if (error) {
                        setFormData(pre => ({ ...pre, consignmentNoteNo: "" }));
                        toast.error(error?.response?.data?.error + "Oops! It seems like the consignment note number you're looking for doesn't exist. Please check and try again.");
                    }
                }
            })();
        }
    }, [id, populateData]); // Add 'populateData' as a dependency


    const validateFormData = (data) => {
        // console.log(data);
        const { weight, rate, ...rest } = data;
        const emptyFields = Object.keys(rest).filter((key) => {
            const value = data[key];

            if (Array.isArray(value)) {
                return value.some(item => {
                    // Check if item is a string before trying to trim
                    if (typeof item === 'string') {
                        return item.trim() === '';
                    }
                    // If item is null or undefined, consider it empty
                    if (item === null || item === undefined) {
                        return true;
                    }
                    // For non-string items, convert to string first
                    return String(item).trim() === '';
                });
            }

            // Handle non-array values
            if (typeof value === 'string') {
                return value.trim() === '';
            }
            return value === '' || value === null || value === undefined;
        });

        if (emptyFields.length > 0) {
            toast.error(`Please fill in all required fields: ${emptyFields.join(', ')}`);
            return false;
        }
        return true;
    };

    const handlePrint = async () => {
        setLoadingAndErrorConfiguration({ isLoading: true, isError: false });
        const elementsToRemove = document.querySelectorAll("#no-need-to-print");
        elementsToRemove.forEach(element => element.remove());
        if (validateFormData(formData)) {
            try {
                await (id ? upddatLR(id, { data: { ...formData, consignmentNoteNo: id } }) : createLh({ data: { ...formData, consignmentNoteNo: formData.consignmentNoteNo } }));
                const formElement = document.querySelector('.max-w-6xl');
                const printElement = formElement.cloneNode(true);

                // Style modifications remain the same
                const inputs = printElement.querySelectorAll('input, textarea');
                inputs.forEach(input => {
                    const parent = input.parentNode;

                    if (input.type === 'radio') {
                        // Radio button handling remains the same
                        const div = document.createElement('div');
                        if (input.checked) {
                            div.innerHTML = '⦿';
                        } else {
                            div.innerHTML = '○';
                        }
                        div.style.display = 'inline-block';
                        div.style.width = '20px';
                        div.style.height = '20px';
                        div.style.lineHeight = '20px';
                        div.style.textAlign = 'center';
                        div.style.fontSize = '16px';

                        const label = parent.querySelector('label');
                        if (label) {
                            const labelDiv = document.createElement('div');
                            labelDiv.style.display = 'inline-block';
                            labelDiv.style.marginLeft = '4px';
                            labelDiv.textContent = label.textContent;

                            const container = document.createElement('div');
                            container.style.display = 'flex';
                            container.style.alignItems = 'center';
                            container.appendChild(div);
                            container.appendChild(labelDiv);

                            parent.replaceChild(container, input);
                        } else {
                            parent.replaceChild(div, input);
                        }
                    } else {
                        // Other input handling remains the same
                        const div = document.createElement('div');
                        div.textContent = input.value;
                        div.style.minHeight = '24px';
                        div.style.padding = '4px 8px';
                        div.style.border = '1px solid #e5e7eb';
                        div.style.borderRadius = '4px';
                        div.style.backgroundColor = '#ffffff';
                        div.style.fontSize = input.style.fontSize || '14px';
                        parent.replaceChild(div, input);
                    }
                });

                printElement.style.position = 'absolute';
                printElement.style.left = '-9999px';
                document.body.appendChild(printElement);

                const canvas = await html2canvas(printElement, {
                    scale: 2,
                    useCORS: true,
                    logging: false,
                    backgroundColor: '#ffffff'
                });

                document.body.removeChild(printElement);

                // Fixed PDF generation logic
                const imgWidth = 210; // A4 width in mm
                const pageHeight = 297; // A4 height in mm
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                const pdf = new jsPDF('p', 'mm', 'a4');

                // Calculate number of pages needed
                const nPages = Math.ceil(imgHeight / pageHeight);

                // Add image for each page
                for (let page = 0; page < nPages; page++) {
                    if (page > 0) {
                        pdf.addPage();
                    }

                    const imgData = canvas.toDataURL('image/png');
                    pdf.addImage(
                        imgData,
                        'PNG',
                        0,
                        page === 0 ? 0 : -(page * pageHeight),
                        imgWidth,
                        imgHeight,
                        '',
                        'FAST'
                    );
                }

                pdf.save('delivery-service-form.pdf');
                window.location.reload()
            } catch (error) {
                toast.error(error?.response?.data?.error);
                console.error('Error generating PDF:', error);
                setLoadingAndErrorConfiguration({ isError: true, isLoading: false })
            } finally {
                setLoadingAndErrorConfiguration({ isError: false, isLoading: false })
            }
        } else {
            setLoadingAndErrorConfiguration({ isLoading: false, isError: false });
        }
    };

    const styles = {
        input: "w-full p-2 border border-gray-300 rounded text-sm focus:outline-none focus:ring-2 focus:ring-blue-500",
        label: "block text-sm font-medium text-gray-700 mb-1",
        section: "border border-gray-300 p-4 rounded"
    };



    useEffect(() => {
        (async () => {
            if (searchingResultsConfig.searchByGST) {
                const res = await getAllUniqueValue("companyGST");
                setUniqueValue(res);
            }
            else if (searchingResultsConfig.searchByPan) {
                const res = await getAllUniqueValue("companyPAN");
                setUniqueValue(res);
            }
            else if (searchingResultsConfig.issueOfficeOrAgent) {
                const res = await getAllUniqueValue("issuingOffice");
                setUniqueValue(res);
            }
            else if (searchingResultsConfig.companyAddress) {
                const res = await getAllUniqueValue("companyAddress");
                setUniqueValue(res);
            }
            else if (searchingResultsConfig.consignorAddress) {
                const res = await getAllUniqueValue("consignorAddress");
                setUniqueValue(res);
            }
            else if (searchingResultsConfig.consigneeAddress) {
                const res = await getAllUniqueValue("consigneeAddress");
                setUniqueValue(res);
            }
            else if (searchingResultsConfig.packingMode) {
                const res = await getAllUniqueValue("packingMode");
                setUniqueValue(res);
            }
            else if (searchingResultsConfig.consignmentNoteNo) {
                const res = await getAllUniqueValue("consignmentNoteNo");
                setUniqueValue(res);
            }
            else if (searchingResultsConfig.origin) {
                const res = await getAllUniqueValue("origin");
                setUniqueValue(res);
            }
            else if (searchingResultsConfig.destination) {
                const res = await getAllUniqueValue("destination");
                setUniqueValue(res);
            }
            else if (searchingResultsConfig.vehicleNo) {
                const res = await getAllUniqueValue("vehicleNo");
                setUniqueValue(res);
            }
            else if (searchingResultsConfig.email) {
                const res = await getAllUniqueValue("email");
                setUniqueValue(res);
            }
        })();

    }, [formData.companyPAN, formData.companyGST, searchingResultsConfig.searchByGST, searchingResultsConfig.searchByPan, searchingResultsConfig.issueOfficeOrAgent, searchingResultsConfig.companyAddress, searchingResultsConfig.consignorAddress, searchingResultsConfig.consigneeAddress, searchingResultsConfig.packingMode, searchingResultsConfig.consignmentNoteNo, searchingResultsConfig.origin, searchingResultsConfig.destination, searchingResultsConfig.vehicleNo, searchingResultsConfig.email]);

    useEffect(() => {
        if (formData.consignmentNoteNo && uniqueValue.includes(formData.consignmentNoteNo)) {
            toast.error("Consignment Note Number already exists");
            // handleInputChange('consignmentNoteNo', '');
            return;
        }
        if (!id) {

            const debouncedToast = debounce((consignmentNoteNo) => {
                if (consignmentNoteNo && consignmentNoteNo.length >= 10 && !validationForConsignmentNoteNo(consignmentNoteNo)) {
                    toast.error("Invalid Consignment Note Number. Format should be: IDP/<current year-previous year>/<number>. Example: IDP/22-23/12345678901234567890");
                }
            }, 900);
            debouncedToast(formData.consignmentNoteNo);
        }
    }, [formData.consignmentNoteNo, uniqueValue, id])
    return (
        <div >
            <div className="max-w-6xl mx-auto p-6 bg-white">
                <div className="space-y-6">
                    {/* Header Section */}
                    <div className={styles.section}>
                        <div className="grid grid-cols-3 gap-6">
                            {/* Company Info */}
                            <div className="space-y-4">
                                <h1 className="text-xl font-bold">Identifyplus Delivery Services Private Limited</h1>
                                <div className="space-y-2">
                                    <div>
                                        <label className={styles.label}>PAN</label>
                                        <input
                                            type="text"
                                            value={formData.companyPAN}
                                            onChange={e => handleInputChange('companyPAN', e.target.value)}
                                            className={styles.input}
                                            placeholder="Enter PAN"
                                            onFocus={() => setSearchingResultsConfig({ searchByPan: true, searchByGST: false })}
                                            onBlur={() => setSearchingResultsConfig({ searchByPan: false, searchByGST: false })}
                                        />
                                        {searchingResultsConfig.searchByPan && uniqueValue.length > 0 && (
                                            <div style={{ maxHeight: "300px", overflowY: "auto" }} className="absolute z-50 mt-1 w-[200px] bg-white border border-gray-300 rounded-md shadow-lg">
                                                {uniqueValue.map((result, index) => (
                                                    <div
                                                        key={index}
                                                        className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                        onMouseDown={(e) => {
                                                            e.preventDefault();
                                                            handleInputChange("companyPAN", result);
                                                        }}
                                                    >
                                                        {result}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <label className={styles.label}>GST No.</label>
                                        <input
                                            type="text"
                                            value={formData.companyGST}
                                            onChange={e => handleInputChange('companyGST', e.target.value)}
                                            className={styles.input}
                                            placeholder="Enter GST No"
                                            onFocus={() => setSearchingResultsConfig({ searchByPan: false, searchByGST: true })}
                                            onBlur={() => setSearchingResultsConfig({ searchByPan: false, searchByGST: false })}
                                        />
                                        {searchingResultsConfig.searchByGST && uniqueValue.length > 0 && (
                                            <div style={{ maxHeight: "300px", overflowY: "auto" }} className="absolute z-50 mt-1 w-[200px] bg-white border border-gray-300 rounded-md shadow-lg">
                                                {uniqueValue.map((result, index) => (
                                                    <div key={index} className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                        onMouseDown={(e) => {
                                                            e.preventDefault();
                                                            handleInputChange("companyGST", result);
                                                            setSearchingResultsConfig({ searchByPan: false, searchByGST: false });
                                                        }}>
                                                        {result}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>

                            {/* Caution Box */}
                            <div className="text-center">
                                <p className="text-sm font-bold mb-2 underline">Subject to Gurgaon Jurisdiction only</p>
                                <p className="text-sm underline mb-2">ACCOUNTS COPY</p>
                                <p className="text-sm underline mb-2">AT OWNER RISK</p>
                                <div className="border border-gray-300 p-2 mt-2">
                                    <p className="text-sm font-bold mb-1">CAUTION</p>
                                    <p className="text-xs leading-relaxed">
                                        {`This consignment will not be detained diverted re-routed or re-booked without
                                    consignee Bank's written permission and will be delivered at the destination.`}
                                    </p>
                                </div>
                            </div>

                            {/* Company Address */}
                            <div>
                                <label className={styles.label}>Company Address</label>
                                <textarea
                                    value={formData.companyAddress}
                                    onChange={e => handleInputChange('companyAddress', e.target.value)}
                                    className={`${styles.input} h-15 resize-none`}
                                    placeholder="Enter company address"
                                    onFocus={() => setSearchingResultsConfig({ companyAddress: true })}
                                    onBlur={() => setSearchingResultsConfig({ companyAddress: false })}
                                />
                                {searchingResultsConfig.companyAddress && uniqueValue.length > 0 && (
                                    <div style={{ maxHeight: "300px", overflowY: "auto" }} className="absolute z-50 mt-1 w-[200px] bg-white border border-gray-300 rounded-md shadow-lg">

                                        {uniqueValue.filter(value => value.toLowerCase().includes(formData.companyAddress.toLowerCase())).map((result, index) => (
                                            <div
                                                key={index}
                                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                onMouseDown={(e) => {
                                                    e.preventDefault();
                                                    handleInputChange("companyAddress", result)
                                                }}
                                            >
                                                {result}
                                            </div>
                                        ))}
                                    </div>
                                )}

                                <label className={styles.label}>Email</label>
                                <input
                                    type='email'
                                    value={formData.email}
                                    onChange={e => handleInputChange('email', e.target.value)}
                                    className={`${styles.input} resize-none`}
                                    placeholder="Enter email"
                                    onFocus={() => setSearchingResultsConfig({ email: true })}
                                    onBlur={() => setSearchingResultsConfig({ email: false })}
                                />
                                {
                                    searchingResultsConfig.email && uniqueValue.length > 0 && (
                                        <div style={{ maxHeight: "300px", overflowY: "auto" }} className="absolute z-50 mt-1 w-[200px] bg-white border border-gray-300 rounded-md shadow-lg">
                                            {uniqueValue?.map((result, index) => (
                                                <div
                                                    key={index}
                                                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                    onMouseDown={(e) => {
                                                        e.preventDefault();
                                                        handleInputChange("email", result);
                                                        setSearchingResultsConfig({ email: false });
                                                    }}
                                                >
                                                    {result}
                                                </div>
                                            ))}
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    </div>

                    {/* Consignment Details */}
                    <div className='flex flex-row'>
                        <div className="grid grid-cols-3 gap-6">
                            {/* Consignor and Consignee */}
                            <div style={{ width: "850px" }} className={`${styles.section} col-span-2`}>
                                <div className="w-full flex flex-row gap-4">
                                    {/* Address Section */}
                                    <div className="w-[70%] flex flex-col space-y-4 border border-gray-300 p-4">
                                        <div className="w-full">
                                            <label className={styles.label}>Consignor Address</label>
                                            <textarea
                                                value={formData.consignorAddress}
                                                onChange={e => handleInputChange('consignorAddress', e.target.value)}
                                                className={`${styles.input} h-10 w-full`}
                                                onFocus={() => setSearchingResultsConfig({ consignorAddress: true })}
                                                onBlur={() => setSearchingResultsConfig({ consignorAddress: false })}
                                            />
                                            {searchingResultsConfig.consignorAddress && uniqueValue.length > 0 && (
                                                <div style={{ maxHeight: "300px", overflowY: "auto" }} className="absolute z-50 mt-1 w-[200px] bg-white border border-gray-300 rounded-md shadow-lg">
                                                    {uniqueValue.filter(value => value.toLowerCase().includes(formData.consignorAddress.toLowerCase())).map((result, index) => (
                                                        <div
                                                            key={index}
                                                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                            onMouseDown={(e) => {
                                                                e.preventDefault();
                                                                handleInputChange("consignorAddress", result)
                                                            }}
                                                        >
                                                            {result}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        <div className="w-full">
                                            <label className={styles.label}>Consignee Address</label>
                                            <textarea
                                                value={formData.consigneeAddress}
                                                onChange={e => handleInputChange('consigneeAddress', e.target.value)}
                                                className={`${styles.input} h-10 w-full`}
                                                onFocus={() => setSearchingResultsConfig({ consigneeAddress: true })}
                                                onBlur={() => setSearchingResultsConfig({ consigneeAddress: false })}
                                            />
                                            {searchingResultsConfig.consigneeAddress && uniqueValue.length > 0 && (
                                                <div className="absolute bg-white border border-gray-300 rounded-md shadow-lg">
                                                    {uniqueValue.filter(value => value.toLowerCase().includes(formData.consigneeAddress.toLowerCase())).map((result, index) => (
                                                        <div
                                                            key={index}
                                                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                            onMouseDown={(e) => {
                                                                e.preventDefault();
                                                                handleInputChange("consigneeAddress", result)
                                                            }}
                                                        >
                                                            {result}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* Consignment Note Section */}
                                    <div className="w-[30%] border border-gray-300 p-4">
                                        <h3 className="text-sm font-bold mb-4">Consignment Note</h3>
                                        <div className="space-y-4">
                                            <div>
                                                <label className={styles.label}>Number</label>
                                                <input
                                                    type="text"
                                                    value={formData.consignmentNoteNo}
                                                    onChange={e => handleInputChange('consignmentNoteNo', e.target.value)}
                                                    className={styles.input}
                                                    disabled={id ? true : false}
                                                    onFocus={() => setSearchingResultsConfig({ consignmentNoteNo: true })}
                                                    onBlur={() => setSearchingResultsConfig({ consignmentNoteNo: false })}
                                                />
                                                {
                                                    searchingResultsConfig.consignmentNoteNo && uniqueValue.length > 0 && (
                                                        <div className="absolute bg-white border border-gray-300 rounded-md shadow-lg">
                                                            {uniqueValue.filter(value => value?.toLowerCase()?.includes(formData.consignmentNoteNo?.toLowerCase()))?.map((result, index) => (
                                                                <div
                                                                    key={index}
                                                                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                                    onMouseDown={(e) => {
                                                                        e.preventDefault();
                                                                        handleInputChange("consignmentNoteNo", result)
                                                                    }}
                                                                >
                                                                    {result}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div>
                                                <label className={styles.label}>Date</label>
                                                <input
                                                    type="date"
                                                    value={formData.consignmentNoteDate}
                                                    onChange={e => handleInputChange('consignmentNoteDate', e.target.value)}
                                                    className={styles.input}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-4 mt-4">
                                    <div>
                                        <label className={styles.label}>Origin</label>
                                        <input
                                            type="text"
                                            value={formData.origin}
                                            onChange={e => handleInputChange('origin', e.target.value)}
                                            className={styles.input}
                                            onFocus={() => setSearchingResultsConfig({ origin: true })}
                                            onBlur={() => setSearchingResultsConfig({ origin: false })}
                                        />
                                        {
                                            searchingResultsConfig.origin && uniqueValue.length > 0 && (
                                                <div style={{ maxHeight: "300px", overflowY: "auto" }} className="absolute z-50 mt-1 w-[200px] bg-white border border-gray-300 rounded-md shadow-lg">
                                                    {uniqueValue.map((result, index) => (
                                                        <div
                                                            key={index}
                                                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                            onMouseDown={(e) => {
                                                                e.preventDefault();
                                                                handleInputChange("origin", result);
                                                                setSearchingResultsConfig({ origin: false });
                                                            }}
                                                        >
                                                            {result}
                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                        }

                                    </div>
                                    <div>
                                        <label className={styles.label}>Destination</label>
                                        <input
                                            type="text"
                                            value={formData.destination}
                                            onChange={e => handleInputChange('destination', e.target.value)}
                                            className={styles.input}
                                            onFocus={() => setSearchingResultsConfig({ destination: true })}
                                            onBlur={() => setSearchingResultsConfig({ destination: false })}
                                        />
                                        {
                                            searchingResultsConfig.destination && uniqueValue.length > 0 && (
                                                <div style={{ maxHeight: "300px", overflowY: "auto" }} className="absolute z-50 mt-1 w-[200px] bg-white border border-gray-300 rounded-md shadow-lg">
                                                    {uniqueValue.map((result, index) => (
                                                        <div
                                                            key={index}
                                                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                            onMouseDown={(e) => {
                                                                e.preventDefault();
                                                                handleInputChange("destination", result);
                                                                setSearchingResultsConfig({ destination: false });
                                                            }}
                                                        >
                                                            {result}
                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div>
                                        <label className={styles.label}>Vehicle No.</label>
                                        <input
                                            type="text"
                                            value={formData.vehicleNo}
                                            onChange={e => handleInputChange('vehicleNo', e.target.value)}
                                            className={styles.input}
                                            onFocus={() => setSearchingResultsConfig({ vehicleNo: true })}
                                            onBlur={() => setSearchingResultsConfig({ vehicleNo: false })}
                                        />
                                        {
                                            searchingResultsConfig.vehicleNo && uniqueValue.length > 0 && (
                                                <div style={{ maxHeight: "300px", overflowY: "auto" }} className="absolute z-50 mt-1 w-[200px] bg-white border border-gray-300 rounded-md shadow-lg">
                                                    {uniqueValue.map((result, index) => (
                                                        <div
                                                            key={index}
                                                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                            onMouseDown={(e) => {
                                                                e.preventDefault();
                                                                handleInputChange("vehicleNo", result);
                                                                setSearchingResultsConfig({ vehicleNo: false });
                                                            }}
                                                        >
                                                            {result}
                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>

                                {/* Package Details Table */}
                                <div className={styles.section}>
                                    <table className="w-full">
                                        <thead>
                                            <tr>
                                                <th className="border p-2 bg-gray-50 text-sm">Packages</th>
                                                <th className="border p-2 bg-gray-50 text-sm">Description</th>
                                                <th className="border p-2 bg-gray-50 text-sm">
                                                    <div>
                                                        <div>Weight</div>
                                                        <div className='flex flex-row justify-between gap-[10px]'>
                                                            <p>Actual</p>
                                                            <p>Charges</p>
                                                        </div>
                                                    </div>

                                                </th>
                                                <th className="border p-2 bg-gray-50 text-sm">Rate</th>
                                                <th className="border p-2 bg-gray-50 text-sm">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="border p-2 w-[20%] min-w-[20%] max-w-[20%] box-border break-words">
                                                    {formData.packages.map((item, index) => (
                                                        <div key={index} className="flex items-center space-x-2">
                                                            <input
                                                                type="number"
                                                                value={item}
                                                                onChange={e => handleInputChange('packages', e.target.value, index)}
                                                                className="w-full border border-gray-300 text-sm"
                                                            />
                                                            {formData.packages?.length > 1 && (
                                                                <div></div>
                                                            )}
                                                        </div>
                                                    ))}

                                                    <div>
                                                        = {formData.packages.reduce((prev, curr) => Number(prev) + Number(curr), 0)}
                                                    </div>

                                                </td>
                                                <td className="border p-2 w-[20%] min-w-[20%] max-w-[20%] box-border break-words">
                                                    <div className="space-y-2">
                                                        <p className="text-sm font-medium">As Per Invoice Attached</p>
                                                        <div>
                                                            <label className="text-sm">Invoice No:</label>
                                                            {formData.invoiceDetails.join(", ")}
                                                        </div>
                                                        <div>
                                                            <label className="text-sm">eWay Bill:</label>
                                                            {formData.eWayBill.map((item, index) => (
                                                                <div key={index} className="flex items-center space-x-2">
                                                                    <input
                                                                        type="text"
                                                                        value={item}
                                                                        onChange={e => handleInputChange('eWayBill', e.target.value, index)}
                                                                        className="w-full border border-gray-300 text-sm"
                                                                        maxLength={12}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div>
                                                            <p className='text-sm'>Date : {formData.invoiceDate}</p>
                                                        </div>
                                                        <div>
                                                            <label>Comm. No.</label>
                                                            {formData?.commertialValues?.map((item, index) => (
                                                                <div key={index} className="flex items-center space-x-2">
                                                                    <input
                                                                        type="text"
                                                                        value={item}
                                                                        onChange={e => handleInputChange('commertialValues', e.target.value, index)}
                                                                        className="w-full border border-gray-300 text-sm"
                                                                    />
                                                                </div>

                                                            ))}
                                                        </div>
                                                        <div>
                                                            <label className="text-sm">Values Of Goods. :</label>
                                                            <div className="text-xs font-light pb-[5px]">
                                                                <p className="text-[10px]">Value of Goods. As Per Manifest / Invoice Attached
                                                                </p>
                                                            </div>
                                                            {formData?.goodsValue.map((item, index) => (
                                                                <div key={index} className="flex items-center space-x-2">
                                                                    <input
                                                                        type="number"
                                                                        value={item}
                                                                        onChange={e => {
                                                                            const value = e.target.value;
                                                                            if (!isNaN(value)) {
                                                                                handleInputChange('goodsValue', value, index);
                                                                            }
                                                                        }}
                                                                        className="w-full border border-gray-300 text-sm"
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>

                                                    </div>
                                                </td>
                                                <td className="border p-2 w-[20%] min-w-[20%] max-w-[20%] box-border break-words">
                                                    {/*<input
                                                        type="number"
                                                        value={formData.weight}
                                                        onChange={e => handleInputChange('weight', e.target.value)}
                                                        className={styles.input}
                                                    />*/}
                                                    {null}
                                                </td>
                                                <td className="border p-2 w-[20%] min-w-[20%] max-w-[20%] box-border break-words">
                                                    {/*<input
                                                        type="number"
                                                        value={formData.rate}
                                                        onChange={e => handleInputChange('rate', e.target.value)}
                                                        className={styles.input}
                                                    />*/}
                                                    {null}
                                                    <div className="flex flex-col m-auto items-center justify-center gap-[5px]">
                                                        {["Freight", "O. Charge", "GST"].map((item, index) => (
                                                            <div key={index} className="font-bolt text-xl">
                                                                {item}
                                                            </div>
                                                        ))}
                                                    </div>

                                                </td>
                                                <td className="border p-2 w-[20%] min-w-[20%] max-w-[20%] box-border break-words">
                                                    {/*<input
                                                        type="text"
                                                        value={formData.amount}
                                                        readOnly
                                                        className={`${styles.input} bg-gray-50`}
                                                    />*/}
                                                    {null}
                                                    <div className="flex flex-col m-auto items-center justify-center gap-[5px]">
                                                        {["To", "Be", "Billed"].map((item, index) => (
                                                            <div key={index} className="font-bolt text-sm">
                                                                {item}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {/* Right Panel */}
                        <div className="w-80 border border-gray-300 divide-y divide-gray-300">
                            {/* Issuing Office */}
                            <div className="p-3 relative ">
                                <label className={styles.label}>
                                    Issuing Office/Agent
                                </label>
                                <input
                                    type="text"
                                    value={formData.issuingOffice}
                                    onChange={e => handleInputChange('issuingOffice', e.target.value)}
                                    className={styles.input}
                                    onFocus={() => setSearchingResultsConfig({ searchByPan: false, searchByGST: false, issueOfficeOrAgent: true })}
                                    onBlur={() => setSearchingResultsConfig({ searchByPan: false, searchByGST: false, issueOfficeOrAgent: false })}
                                />
                                {searchingResultsConfig.issueOfficeOrAgent && (
                                    <div
                                        style={{ zIndex: "1000" }}
                                        className="absolute top-full left-0 w-full max-h-36 overflow-y-auto bg-white shadow-md p-2"
                                    >
                                        {uniqueValue
                                            .filter((item, index) => (
                                                item.toLowerCase().includes(formData.issuingOffice.toLowerCase())
                                            ))
                                            .map((item, index) => (
                                                <div key={index} className="py-1" onMouseDown={() => {
                                                    handleInputChange('issuingOffice', item);
                                                }}>{item}</div>
                                            ))}
                                    </div>
                                )}
                            </div>

                            {/* Mode of Packing */}
                            <div className="p-3 relative">
                                <label className={styles.label}>
                                    Mode of Packing
                                </label>
                                <input
                                    type="text"
                                    value={formData.packingMode}
                                    onChange={e => handleInputChange('packingMode', e.target.value)}
                                    className={styles.input}
                                    onFocus={() => setSearchingResultsConfig({ packingMode: true })}
                                    onBlur={() => setSearchingResultsConfig({ packingMode: false })}
                                />
                                {searchingResultsConfig.packingMode && (
                                    <div
                                        className="absolute top-full left-0 w-full max-h-36 overflow-y-auto bg-white shadow-md p-2"
                                    >
                                        {uniqueValue
                                            .filter((item, index) => (
                                                item.toLowerCase().includes(formData.packingMode.toLowerCase())
                                            ))
                                            .map((item, index) => (
                                                <div key={index} className="py-1" onMouseDown={() => {
                                                    handleInputChange('packingMode', item);
                                                }}>{item}</div>
                                            ))}
                                    </div>
                                )}
                            </div>

                            {/* Invoice Details */}
                            <div className="p-3">
                                <label className={styles.label}>
                                    Invoice No. & Date
                                </label>
                                <div className="space-y-2">
                                    {formData.invoiceDetails.map((inv, index) => (
                                        <div key={index} className="flex items-center">
                                            <input
                                                type="text"
                                                value={inv}
                                                onChange={(e) => {
                                                    const newInvoiceDetails = [...formData.invoiceDetails];
                                                    newInvoiceDetails[index] = e.target.value;
                                                    handleInputChange('invoiceDetails', newInvoiceDetails);
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' && inv.trim() !== '') {
                                                        const newInvoiceDetails = [...formData.invoiceDetails, ''];
                                                        const newPackages = [...formData.packages, ''];
                                                        const newEwayBill = [...formData.eWayBill, ''];
                                                        handleInputChange('invoiceDetails', newInvoiceDetails);
                                                        handleInputChange('packages', newPackages);
                                                        handleInputChange('eWayBill', newEwayBill);
                                                        handleInputChange('goodsValue', [...formData.goodsValue, '']);
                                                        handleInputChange("commertialValues", [...formData.commertialValues, ''])
                                                    }
                                                }}
                                                className="w-full p-1 border border-gray-300 text-sm"
                                            />
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    const newInvoiceDetails = [...formData.invoiceDetails];
                                                    newInvoiceDetails.splice(index, 1);
                                                    const newPackages = [...formData.packages];
                                                    newPackages.splice(index, 1);
                                                    const newEwayBill = [...formData.eWayBill];
                                                    newEwayBill.splice(index, 1);
                                                    handleInputChange('invoiceDetails', newInvoiceDetails);
                                                    handleInputChange('packages', newPackages);
                                                    handleInputChange('eWayBill', newEwayBill);
                                                    handleInputChange('goodsValue', [...formData.goodsValue, '']);
                                                    handleInputChange("commertialValues", [...formData.commertialValues, ''])
                                                }}
                                                className="ml-2 text-red-500 hover:text-red-700 focus:outline-none"
                                                id='no-need-to-print'
                                            >
                                                <XIcon className="w-5 h-5" />
                                            </button>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        onClick={() => {
                                            if (formData.invoiceDetails[formData.invoiceDetails.length - 1].trim() !== '') {
                                                const newInvoiceDetails = [...formData.invoiceDetails, ''];
                                                handleInputChange('invoiceDetails', newInvoiceDetails);
                                                const newPackages = [...formData.packages, ''];
                                                handleInputChange('packages', newPackages);
                                                const newEwayBill = [...formData.eWayBill, ''];
                                                handleInputChange('eWayBill', newEwayBill);
                                                const newGoodsValue = [...formData.goodsValue, ''];
                                                handleInputChange('goodsValue', newGoodsValue);
                                                handleInputChange("commertialValues", [...formData.commertialValues, ''])

                                            }
                                        }}
                                        className="w-full p-1 border border-gray-300 text-sm"
                                    >
                                        Add Invoice Details
                                    </button>
                                    <label className={styles.label} style={{ width: '70px' }}>Date:</label>
                                    <input
                                        type="date"
                                        value={formData.invoiceDate}
                                        onChange={e => handleInputChange('invoiceDate', e.target.value)}
                                        className={styles.input}
                                        style={{ width: 'calc(100% - 10px)' }}
                                    />
                                </div>
                            </div>

                            {/* GST Payable Section */}
                            <div className="p-3">
                                <p className="text-sm font-medium mb-2">GST Payable by:</p>
                                <div className="space-y-2">
                                    {['consignor', 'consignee', 'transporter'].map((option) => (
                                        <label key={option} className="flex items-center">
                                            <input
                                                type="radio"
                                                name="gstPayableBy"
                                                checked={formData.gstPayableBy === option}
                                                onChange={() => handleInputChange('gstPayableBy', option)}
                                                className="mr-2"
                                            />
                                            <span className="text-sm capitalize">{option}</span>
                                        </label>
                                    ))}
                                </div>
                            </div>

                            {/* Remarks */}
                            <div className="p-3">
                                <label className={styles.label}>
                                    Remarks
                                </label>
                                <textarea
                                    value={formData.remarks}
                                    onChange={(e) => handleInputChange('remarks', e.target.value)}
                                    className="w-full p-1 border border-gray-300 text-sm min-h-[60px] resize-none"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Footer Section */}
                    <div className={styles.section}>
                        <div className="flex justify-between items-end">
                            <div>
                                <label className={styles.label}>Date</label>
                                <input
                                    type="date"
                                    value={formData.date}
                                    onChange={e => handleInputChange('date', e.target.value)}
                                    className={styles.input}
                                />
                                <p className="mt-8 text-sm font-medium">Party Sign ...............</p>
                            </div>
                            <div className="text-right">
                                <p className="text-sm font-medium">For, Identifyplus Delivery Services Private Limited</p>
                                <p className="text-xs mt-8">Authorized Signatory</p>
                            </div>
                        </div>
                        {/* System Generated Note */}
                        <div className="text-center text-sm text-gray-500">
                            <p>Note: This is System Generated LR. Hence does not required signature</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Generate PDF Button */}
            <div className="print:hidden text-right ">
                <button
                    style={{ position: "fixed", top: "90vh", left: "88%" }}
                    onClick={handlePrint}
                    className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-2 rounded-lg flex items-center ml-auto transition-colors"
                >
                    {loadingAndErrorConfiguration?.isLoading ? (
                        <div className="flex items-center">
                            <Clock className="w-4 h-4 mr-2 animate-spin" />
                            <span>Loading...</span>
                        </div>
                    ) : (
                        <div className="flex flex-row items-center">
                            <Printer className="w-4 h-4 mr-2" />
                            <span>Generate PDF</span>
                        </div>
                    )}
                </button>
            </div>

        </div>
    );
};

export default DeliveryServiceForm;